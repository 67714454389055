<template>
  <div>
    <h1
      v-if="isFilled('roles')"
      class="text-h6 text-md-h4 mt-6"
      :class="fontWeightLightMd"
    >
      {{ $t('profile.roles.title') }}
    </h1>
    <h1
      v-if="!isFilled('roles')"
      class="text-h6 mt-6"
      :class="fontWeightLightMd"
    >
      Además desempeñaré el cargo
    </h1>
    <v-item-group multiple active-class="primary" v-model="selected">
      <v-row>
        <v-col :key="`card-${i}`" cols="6" md="3" v-for="(item, i) in items">
          <v-item :value="item.code" v-slot:default="{ active, toggle }">
            <v-card
              :color="active ? 'identity white--text' : ''"
              @click="toggle"
              width="170"
              rounded="lg"
            >
              <v-scroll-y-transition>
                <div class="text-h2">
                  <v-img
                    class="align-self-stretch"
                    src="../../assets/bg-ticket.jpg"
                    aspect-ratio="1.7"
                  >
                    <v-container class="fill-height pa-0" v-if="active">
                      <v-row align="end">
                        <v-row justify="center">
                          <div>
                            <v-icon large dark>{{ item.icon }}</v-icon>
                            <v-icon dark>mdi-checkbox-marked-circle</v-icon>
                          </div>
                        </v-row>
                      </v-row>
                    </v-container>
                  </v-img>

                  <v-card-text primary-title class="button d-block text-center">
                    <span class="d-block text-body-2 dark">{{
                      item.purpose
                    }}</span>
                    <h1 class="d-block text-h5" :class="siteNameStyle">
                      {{ item.name }}
                    </h1>
                    <v-btn
                      color="white identity--text"
                      @click.stop="openMore(item.code)"
                      small
                      rounded
                      class="mt-2"
                      >ver más...</v-btn
                    >
                  </v-card-text>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <roles-dialog
      ref="dialog"
      :item="roleDescriptionBySelection"
    ></roles-dialog>
  </div>
</template>

<script>
import { managedImg } from '@/micro/img'
import { siteNameText } from '@/micro/text'
import { mapGetters, mapActions } from 'vuex'
import { getRoles } from '@/store/lite/roles'
import { arrayIntersection, arrayFind } from '@/micro/array'
import RolesDialog from '@/components/orgs/RolesDialog'
import { initAssignments } from '../people/fbApi'

export default {
  components: {
    RolesDialog
  },
  data: () => ({
    selected: [],
    roleDescriptionBySelection: {
      name: '',
      purpose: '',
      functions: []
    }
  }),
  props: {
    initRoles: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('profile', ['draft', 'isFilled', 'isValidProfileRequest']),
    items() {
      return getRoles()
    },
    siteNameStyle() {
      return siteNameText(this.$vuetify.breakpoint)
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    }
  },

  watch: {
    async selected(newRoles) {
      if (newRoles.length > 1) {
        if (newRoles.length === 2) {
          if (
            arrayIntersection(['f', 'd'], newRoles).length < 2 &&
            arrayFind('f', newRoles)
          ) {
            let i = newRoles.findIndex(role => role === 'f')
            this.selected.splice(i, 1)
          }
        } else {
          if (arrayFind('f', newRoles)) {
            let i = newRoles.findIndex(role => role === 'f')
            this.selected.splice(i, 1)
          }
        }
      }
      this.saveDraft({ key: 'roles', value: newRoles })
      // It is true 'cause it is optional
      // this.updateValidSection({ section: 'roles', value: true })
      if (this.isValidProfileRequest) {
        this.setFieldsAccepted(this.isValidProfileRequest)
        await initAssignments(this.draft.ou, this.draft.email, this.draft.roles)
      }
    },

    initRoles(newValue, oldValue) {
      if (oldValue === null && newValue) {
        this.selected = [...this.selected, ...newValue]
      }
    }
  },

  methods: {
    managedImg,
    ...mapActions('requirements', ['setFieldsAccepted']),
    ...mapActions('profile', ['saveDraft', 'updateValidSection']),
    openMore(code) {
      this.roleDescriptionBySelection = this.items.find(
        item => item.code === code
      )
      this.$refs.dialog.open()
    }
  }
}
</script>
