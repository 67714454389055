<template>
  <v-container class="mt-6">
    <div v-if="!isValidProfileRequest">
      <span
        class="text-h6 text-md-h5 grey--text text--darken-2 font-weight-light"
        >{{ preTitle }}</span
      >
      <h1 class="text-h5 text-md-h3 mb-0 mb-md-9" :class="fontWeightLightMd">
        {{ title }}
      </h1>
    </div>

    <div v-else>
      <h1 class="text-h5 text-md-h3 mb-0 mb-md-9" :class="fontWeightLightMd">
        {{ preTitle }}
      </h1>
      <v-alert rounded="lg" type="success" color="light-green darken-1" text>
        <v-row align="center">
          <v-col cols="7"
            ><h1 class="text-h6 text-md-h4" :class="fontWeightLightMd">
              {{ title }}
            </h1></v-col
          >
          <v-col cols="5"
            ><v-btn
              v-if="isValidProfileRequest"
              to="Documents"
              class="mx-2"
              fab
              dark
              color="identity white--text lg"
            >
              <v-icon dark>
                mdi-chevron-right
              </v-icon>
            </v-btn></v-col
          >
        </v-row>
      </v-alert>
    </div>

    <employee-profile />

    <v-alert
      rounded="lg"
      type="success"
      class="mt-3"
      color="light-green darken-1"
      text
      v-if="isValidProfileRequest"
    >
      {{ profileCompleteMsg }}
    </v-alert>
    <v-snackbar v-model="snackbar">
      {{ profileCompleteMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <div
      v-if="isValidProfileRequest"
      class="d-flex justify-center align-center mt-3"
    >
      <v-btn to="Documents" color="identity--text" text>{{
        $t('profile.btnTitle')
      }}</v-btn>
      <v-btn
        to="Documents"
        class="mx-2"
        fab
        dark
        color="identity white--text lg"
      >
        <v-icon dark>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>

    <v-overlay :value="isVisibleOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import EmployeeProfile from '@/components/people/EmployeeProfile'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Requirements',
  data: () => ({
    snackbar: false,
    profileCompleteMsg: '¡Gracias! Tu perfil está completo.'
  }),
  components: {
    EmployeeProfile
  },
  computed: {
    ...mapGetters('profile', ['isVisibleOverlay', 'isValidProfileRequest']),
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    preTitle() {
      return this.isValidProfileRequest
        ? this.$t('profile.preTitleFulfilled')
        : this.$t('profile.preTitle')
    },
    title() {
      return this.isValidProfileRequest
        ? this.$t('profile.titleFulfilled')
        : this.$t('profile.title')
    }
  },
  watch: {
    isValidProfileRequest(fulfilled) {
      if (fulfilled) {
        this.snackbar = true
        this.addSectionCompleted('a')
        this.enableRoute('Documents')
      }
    }
  },
  methods: {
    ...mapActions('routes', ['enableRoute']),
    ...mapActions('user', ['addSectionCompleted'])
  }
}
</script>
