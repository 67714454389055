import firebase from 'firebase/app'
import 'firebase/functions'

export async function initAssignments(pubOid, pubSid, pubRoles) {
  const addEmployee = firebase
    .functions()
    .httpsCallable('users-AddEmployeeIndex')
  const data = { o: pubOid, s: pubSid, r: pubRoles }
  try {
    await addEmployee(data)
  } catch (error) {
    console.log('initAssignments', error)
  }
}

export async function getOrgProfile() {
  const getOrgProfile = firebase
    .functions()
    .httpsCallable('users-GetEmployeeOrgProfileIndex')
  const data = {}
  const empty = { o: '', s: '', l: '', r: [] }
  try {
    const result = await getOrgProfile(data)
    return result.data.data || empty
  } catch (error) {
    return empty
  }
}
